$font-family-base: 'Raleway', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/typeface-montserrat/index.css";
@import "../node_modules/typeface-raleway/index.css";

body {
  background-color: #f4f4f4;
  color: #444;
  letter-spacing: 0.05em; 
  min-height: 100vh;
}

#main {
  overflow: hidden;
}

#footer {
  color: #bbb;
  font-weight: 400;
  font-size: 0.8em;
  letter-spacing: 0.25em;
}

#content {
  background-color: white;
}

#image {
  background-image: url("assets/jewiet-photo.jpg");
  background-repeat: no-repeat;
  background-position: 38% 30%;
  transform: translate(0, 1em);
  background-size: cover;
  min-height: 320px;
  max-height: 1079px;
}

a[href] {
  color: #1184CE;
}

